<div
    class="profile-item"
    [routerLink]="isRoleActive ? role().route : null"
    [escTooltip]="disabledMessage"
    [attr.data-test]="'profile-role-' + role().role.toLowerCase()"
    (click)="isRoleActive ? onClick() : null"
    [ngClass]="{
        'is-disabled': !isRoleActive,
        'is-active': isRoleCurrent
    }">
    <div class="profile-item__content">
        <p class="profile-item__role">
            <span>
                <span class="text-body-small-strong">{{ role().roleName }}</span>
                <span class="profile-item__franchise truncate" *ngIf="hasMultipleFranchises && role().franchise">
                    #{{ role().franchise?.franchise?.id }} - {{ getFranchiseName(role().franchise?.franchise) }}
                </span>
            </span>
        </p>
    </div>

    <div class="profile-item__icon" [ngStyle]="{ 'background-color': roleColor() }">
        <esc-icon [icon]="roleIcon()" large />
    </div>
</div>
