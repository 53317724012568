<header class="header">
    <div class="header__top">
        <div class="container">
            <div class="header__content">
                <div class="header__column" #container>
                    <a [routerLink]="['/']" class="header__logo">
                        <span class="earlystage-logo-full" *ngIf="(isMobile$ | async) === false">
                            <img [src]="'/assets/images/earlystage-logo-full.svg'" alt="Early Stage Office" />
                        </span>
                        <span class="earlystage-logo" *ngIf="isMobile$ | async">
                            <img [src]="'/assets/images/earlystage-logo-mobile.svg'" alt="Early Stage Office" />
                        </span>
                    </a>
                </div>
                <div class="header__column is-center">
                    <ng-template #dynamicComponentContainer></ng-template>
                </div>

                <div class="header__column">
                    <div *ngIf="!isMobile; else mobileTemplate" class="header__profile flex">
                        <es-header-profile></es-header-profile>
                    </div>
                    <ng-template #mobileTemplate>
                        <div class="header-side-wrapper">
                            <button type="button" class="toggler" (click)="openSidebar()">
                                <esc-icon icon="menu" large />
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isMobile || menuDynamicItems?.length > 0" class="header__menu">
        <div class="inactive-role-error" *ngIf="isUserInactive$ | async; else headerNav">
            <div class="inactive-role-error__icon">
                <esc-icon [icon]="'info'" class="text-color-white"></esc-icon>
            </div>
            <p class="inactive-role-error__text">Twoje konto jest aktualnie nieaktywne</p>
        </div>

        <ng-template #headerNav>
            <div class="container">
                <ng-container *ngIf="menuDynamicItems?.length > 0">
                    <div class="header__menu-items flex" #menuContainer></div>
                </ng-container>
                <ng-container *ngIf="!isMobile && !(menuDynamicItems?.length > 0)">
                    <es-navigation class="header__nav"></es-navigation>
                </ng-container>
            </div>
        </ng-template>
    </div>
</header>
