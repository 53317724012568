<a class="profile-item" [ngClass]="{ 'is-link': canExpand }" (click)="canExpand ? toggleExpand($event) : null" [routerLink]="canRoute() ? role().route : null">
    <div class="profile-item__content" *ngIf="profile$ | async as profile">
        <p class="profile-item__name">{{ profile.user.firstName }} {{ profile.user.lastName }}</p>
        <p class="profile-item__role">
            {{ role()?.roleName }}
        </p>
        <ng-container *ngIf="franchise$ | async as franchise">
            <p class="profile-item__franchise truncate" *ngIf="hasMultipleFranchises || role()?.role === 'ROLE_SUPER_ADMIN'">
                #{{ franchise.id }} - {{ getFranchiseName(franchise) }}
            </p>
        </ng-container>
    </div>

    <div class="profile-item__icon" [ngStyle]="{ 'background-color': roleColor() }">
        <esc-icon [icon]="roleIcon()" large />
    </div>

    <span class="profile-item__arrow" [ngClass]="{ 'is-active': expand$ | async, 'is-mobile': isMobile$ | async }">
        <esc-icon icon="expand_more"></esc-icon>
    </span>
</a>
